<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#F2F2F2;"
      points="430.1,488.329 386.575,512 343.05,488.329 299.525,512 256.001,488.329 212.475,512 
	168.95,488.329 125.425,512 81.9,488.329 81.9,0 430.1,0 "
    />
    <g>
      <rect
        x="166.993"
        y="55.053"
        style="fill:#57A3A7;"
        width="178.004"
        height="18.352"
      />
      <rect
        x="288.79"
        y="135.794"
        style="fill:#57A3A7;"
        width="85.68"
        height="18.352"
      />
      <rect
        x="137.531"
        y="135.794"
        style="fill:#57A3A7;"
        width="125.566"
        height="18.352"
      />
      <rect
        x="201.923"
        y="225.72"
        style="fill:#57A3A7;"
        width="172.547"
        height="18.352"
      />
      <rect
        x="137.531"
        y="225.72"
        style="fill:#57A3A7;"
        width="38.702"
        height="18.352"
      />
      <rect
        x="137.531"
        y="180.757"
        style="fill:#57A3A7;"
        width="236.951"
        height="18.352"
      />
      <rect
        x="314.483"
        y="369.185"
        style="fill:#57A3A7;"
        width="59.987"
        height="18.352"
      />
      <rect
        x="253.309"
        y="405.889"
        style="fill:#57A3A7;"
        width="121.161"
        height="18.352"
      />
      <rect
        x="137.531"
        y="369.185"
        style="fill:#57A3A7;"
        width="148.812"
        height="18.352"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
